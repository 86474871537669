const rootPath = '/app-edge';
const adnsResolver = `${rootPath}/adns-resolver`;

export const addOverRidesPath = 'add/add-overrides';
export const editOverRidesPath = 'edit/add-overrides';
export const addDnsProfilePath = 'security-profiles/add';
export const editDnsProfilePath = 'security-profiles/edit';
export const addManageEdlDefinitionsPath = 'add/manage-edl-definitions';
export const editManageEdlDefinitionsPath = 'edit/manage-edl-definitions';

export const routeUrls = {
  rootPath,
  adnsResolver,
  edlDefinitions: `${adnsResolver}/edl-definitions`,
  manageEdlCerts: `${adnsResolver}/edl-definitions/manage-certs`,
  addEdlDefinition: `${adnsResolver}/edl-definitions/add`,
  editEdlDefinition: `${adnsResolver}/edl-definitions/edit`,
  securityProfiles: `${adnsResolver}/security-profiles`,
  addSecurityProfile: `${adnsResolver}/${addDnsProfilePath}`,
  editSecurityProfile: `${adnsResolver}/${editDnsProfilePath}`,
  addManageEdlDefinitions: `${adnsResolver}/security-profiles/${addManageEdlDefinitionsPath}`,
  editManageEdlDefinitions: `${adnsResolver}/security-profiles/${editManageEdlDefinitionsPath}`,
  addOverrides: `${adnsResolver}/security-profiles/${addOverRidesPath}`,
  editOverrides: `${adnsResolver}/security-profiles/${editOverRidesPath}`,
  manageDnsMisconfigDomains: `${adnsResolver}/security-profiles/edit/dns-misconfig-domains`,

  networkObjects: `${adnsResolver}/network-objects`,
  sinkholeSettings: `${adnsResolver}/settings`,
  settingsPreview: `${adnsResolver}/settings/preview`,

  addNetworkObject: `${adnsResolver}/add-network-object`,
  addNetworkObjectConfirmation: `${adnsResolver}/add-network-object/confirmation`,
  editNetworkObject: `${adnsResolver}/edit-network-object`,
  manageSubnets: `${adnsResolver}/edit-network-object/subnets`,
  addSubnet: `${adnsResolver}/edit-network-object/subnets/add`,
  verifySubnet: `${adnsResolver}/edit-network-object/subnets/verify`,
  profiles: `${adnsResolver}/profiles`,
  addProfile: `${adnsResolver}/profiles/add`,
  editProfile: `${adnsResolver}/profiles/edit`,

  unAuthorized: `${adnsResolver}/unauthorized`,
} as const;
